/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// Libraries
import 'bootstrap';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { Notifier } from '@airbrake/browser';

// Stimulus
import './bootstrap.js';

// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './css/app.css';

// Initialise FingerprintJS
const fpPromise = FingerprintJS.load();

// Configure FingerprintJS
fpPromise
    .then((fp) => fp.get())
    .then(({ visitorId, components }) => {
        document.cookie = `fingerprint=${visitorId}; path=/; max-age=3600`;
    });

// Initialise Airbrake
const airbrake = new Notifier({
    projectId: 518922,
    projectKey: '189c745d5f1c579476dac97f600286d7',
    environment: 'production',
});

// Configure Airbrake
airbrake.addFilter((notice) => {
    const validPaths = ['assets/js/', 'build/'];
    const invalidFiles = ['notifier.js', 'fingerprint.js'];
    let shouldFilter = false;

    notice.errors.forEach((error) => {
        error.backtrace.forEach((fileInfo) => {
            const file = fileInfo.file;

            // Check whether the file path is in the list of valid paths
            if (!validPaths.some((pathName) => file.includes(pathName))) {
                // Filter if the traced file is not a valid path
                shouldFilter = true;
            }

            // Check whether the file name is in the list of invalid files
            if (invalidFiles.some((fileName) => file.includes(fileName))) {
                // Filter if the traced file is an invalid file
                shouldFilter = true;
            }
        });
    });

    if (shouldFilter === true) {
        return null;
    }

    return notice;
});

// Adds a fixed position div to the body to fix a bug on Samsung devices
if (
    navigator.userAgent.match(
        /SAMSUNG|Samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i
    )
) {
    const div = document.createElement('div');

    div.classList.add('position-fixed');
    document.body.appendChild(div);
}

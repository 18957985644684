// Libraries
import { Controller } from '@hotwired/stimulus';

// Utilities
import { getCookie, setCookie } from '../utilities/cookie';

/**
 * @class CookieBannerController
 * @description Cookie information banner controls.
 */
class CookieBannerController extends Controller {
    /**
     * @method connect
     * @description Show the cookie banner if the cookieSeen cookie has not been set.
     *
     * @return {void}
     */
    connect() {
        const cookieSeen = getCookie('cookieSeen');

        if (!cookieSeen) {
            this.element.classList.add('show');
        }
    }

    /**
     * @method setCookieSeen
     * @description Set the cookieShown cookie and hide the banner.
     *
     * @return {void}
     */
    setCookieSeen() {
        setCookie('cookieSeen', 'shown', 10 * 365);
        this.element.classList.remove('show');
    }
}

// Exports
export default CookieBannerController;

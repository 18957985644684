/**
 * @function setCookie
 * Set a cookie with the given name, value, and expiration date
 *
 * @param {string} name - Cookie name
 * @param {string} value - Cookie value
 * @param {number} days - Number of days until the cookie expires
 * @return {void}
 */
export function setCookie(name, value, days) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + days);

    const cookieString = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
    document.cookie = cookieString;
}

/**
 * @function getCookie
 * Get the value of a cookie with the given name
 *
 * @param {string} name - Cookie name
 * @return {string|null} - Cookie value or null if not found
 */
export function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
        const [cookieName, cookieValue] = cookie.trim().split('=');

        if (cookieName === name) {
            return cookieValue;
        }
    }

    return null;
}
